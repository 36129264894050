import accountBtn from './account_btn';
import swiperInit from './swiper';
import searchModal from './search_modal';
import expandable from './expandable';

(() => {
  document.addEventListener('turbo:load', () => {
    swiperInit();
    searchModal();
    accountBtn();
    expandable();
	})
})();
