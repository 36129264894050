export default () => {
  const modal = document.querySelector('.search-modal');
  const searchBtn = document.querySelector('.page-banner__search');

  if(searchBtn && modal){
    searchBtn.addEventListener('click', () => {
      modal.classList.add('open');
    })
    modal.addEventListener('click', (e) => {
      if(e.target.className == 'search-modal open'){
        modal.classList.remove('open');
      }
    })
  }

};