import "@hotwired/turbo-rails"
import "@rails/actiontext"
import "./controllers"
import "./modules/index"
import "trix"
import "@rails/actiontext"



if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                           console.log('[Companion]', 'Service worker registered!')
                           console.log(registration)
                         })
}
