import { Controller } from "@hotwired/stimulus"

const options = {
  enableHighAccuracy: true,
  maximumAge: 0
};

// Connects to data-controller="location"
export default class extends Controller {
  static values = { url: String }

  search() {
    if((this.getCookie('lat')) && (this.getCookie('lng'))) { return }

    navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error, options);
  }

  success(pos) {
    const crd = pos.coords;
    document.cookie = "lat="+`${crd.latitude}`;
    document.cookie = "lng="+`${crd.longitude}`;
  }

  error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  connect() {
    this.search();
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}
