export default () => {
  const btn = document.querySelector('.site-header__links').querySelector('.btn');
  const linksContainer = document.querySelector('.site-header__links > div > div');

  if(btn && linksContainer){
    btn.addEventListener('click', () => {
      linksContainer.classList.toggle('open');
    })
    document.addEventListener('click', (e) => {
      if(e.target.className !== 'open' && linksContainer.classList == 'open' && e.target !== btn){
        linksContainer.classList.remove('open');
      }
    })
  }
};