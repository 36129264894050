import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq-open"
export default class extends Controller {
  static targets = ['answer', 'btn']

  open() {
    this.answerTarget.classList.toggle('open');
    let svg = this.btnTarget.querySelector('svg');
    svg.classList.toggle('spin');    
  }
}
