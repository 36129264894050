import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
  // const swiperCategory = new Swiper('.category-grid__slider', {
  //   modules: [Navigation, Pagination],    
  //   direction: 'horizontal',
  //   loop: true,
  //   // slidesPerView: 5.5,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //   breakpoints: {
  //     0: {
  //       slidesPerView: 1.5,
  //       spaceBetween: 40
  //     },
  //     375: {
  //       slidesPerView: 1.6,
  //       spaceBetween: 10
  //     },
  //     600: {
  //       slidesPerView: 2.5,
  //       spaceBetween: 0
  //     },
  //     768: {
  //       slidesPerView: 3.5,
  //       spaceBetween: 60
  //     },
  //     1024: {
  //       slidesPerView: 4.5,
  //       spaceBetween: 10
  //     },
  //     1300: {
  //       slidesPerView: 5.5,
  //       spaceBetween: 10
  //     }
  //   },
  // });

  const swiperService = new Swiper('.swiper-service', {
    modules: [Navigation, Pagination],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween:10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  
};