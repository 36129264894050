import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["btn", "nav"]

  open() {
    this.navTarget.classList.toggle("open");
  }
}
