import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="review-stars"
export default class extends Controller {
  connect() {
    const ratingsContainer = document.querySelector('.review_rating');
    const error = ratingsContainer.querySelector('.error')
    ratingsContainer.innerHTML = 
    '<label class="radio_buttons required"><abbr title="required">*</abbr> Rating</label><input type="hidden" name="review[rating]" value="" autocomplete="off"><div class="stars"><input class="radio_buttons required" type="radio" value="1" name="review[rating]" id="review_rating_1"><input class="radio_buttons required" type="radio" value="2" name="review[rating]" id="review_rating_2"><input class="radio_buttons required" type="radio" value="3" name="review[rating]" id="review_rating_3"><input class="radio_buttons required" type="radio" value="4" name="review[rating]" id="review_rating_4"><input class="radio_buttons required" type="radio" value="5" name="review[rating]" id="review_rating_5"><i></i></div>';
    if(error){
      ratingsContainer.appendChild(error);
    }
  }
}
